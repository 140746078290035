import { styled } from '@slal/ui';
import { Badge } from '@slal/ui/display';
import { Input, InputGroup } from '@slal/ui/forms';
import { breakpointUp } from '@slal/ui/helpers';
import { SearchIcon } from '@slal/ui/icons';
import { theme } from '@slal/ui/theme';
import { DocumentLink, Notice, Roundel } from '~/components';

type FooterProps = {
    isNoticeVisible: boolean;
};

export const StyledFundOptions = styled.section`
    table {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }
`;

export const StyledFundOptionsFooter = styled.footer<FooterProps>`
    display: flex;
    justify-content: ${({ isNoticeVisible }) => (isNoticeVisible ? 'space-between' : 'flex-end')};
    align-items: center;
    max-height: ${({ theme }) => theme.space[7]};
    margin-bottom: ${({ theme }) => theme.space[6]};
    margin-top: ${({ theme }) => theme.space[6]};
`;

export const StyledFundList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const StyledFundListItem = styled.li`
    dl {
        margin-bottom: 0;
    }

    dt {
        display: inline-block;
        width: 45%;
        vertical-align: top;
    }

    dd {
        display: inline-block;
        width: 55%;
    }
`;

export const FundSearch = styled(Input)`
    position: relative;
    display: flex;
    align-items: center;
`;

export const FundSearchContainer = styled(InputGroup)`
    margin-bottom: ${({ theme }) => theme.space[6]};

    label {
        background: none;
        margin-left: -45px !important;
        border: none;
    }
`;

export const FundSearchIcon = styled(SearchIcon)`
    margin-top: 6px;
`;

export const NoSearchResultsNotice = styled(Notice)`
    width: 200px;
    ${breakpointUp.xs`
        width: fit-content;    
    `}
`;

export const StyledVolatilityRating = styled.aside`
    max-width: 440px;
    margin-bottom: ${({ theme }) => theme.space[6]};
    h3 {
        margin-bottom: ${({ theme }) => theme.space[5]};
    }
`;

export const StyledVolatilityRatingHeadings = styled.header`
    display: flex;
    justify-content: space-between;
`;

export const StyledVolatilityRatings = styled.div`
    display: flex;
    justify-content: space-between;
`;

const getRatingColor = (index: number) => {
    switch (index) {
        case 7:
            return theme.colors.orange__80;
        case 6:
            return theme.colors.orange__70;
        case 5:
            return theme.colors.orange__60;
        case 4:
            return theme.colors.orange__50;
        case 3:
            return theme.colors.yellow__70;
        case 2:
            return theme.colors.yellow__50;
        case 1:
            return theme.colors.yellow__30;
        default:
            'inherit';
    }
};

export const RatingBadge = styled(Badge)<{ index: number }>`
    background-color: ${({ index }) => getRatingColor(index)};
    border-color: ${({ index }) => getRatingColor(index)};
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};

    ${breakpointUp.xs`    
        min-width: 40px;
    `}
    ${breakpointUp.md`    
        min-width: 50px;
    `}
`;
export const StyledRoundel = styled(Roundel)`
    display: inline-flex;
    min-height: 20px;
    min-width: 20px;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.primaryText};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};
`;

export const StyledHeadingRoundel = styled(StyledRoundel)`
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const StyledTableHeadingRoundel = styled(StyledRoundel)`
    margin-left: ${({ theme }) => theme.space[3]};
    background-color: ${({ theme }) => theme.colors.primaryText};
`;

export const FundChoicesLink = styled(DocumentLink)`
    margin-bottom: ${({ theme }) => theme.space[6]};
    padding-top: 0;
`;
