import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const CreatePensionConsolidationRequest: DocumentNode = gql`
    mutation CreatePensionConsolidationRequest($input: CreatePensionConsolidationRequestInput!) {
        createPensionConsolidationRequest(input: $input) {
            success
        }
    }
`;
