import { Button } from '@slal/ui/buttons';
import { Modal, ModalBody } from '@slal/ui/modal';
import { Article, P } from '@slal/ui/typography';
import { useCookieBanner } from './hooks';
import { StyledLink, StyledModalFooter } from './styles';

export const CookieBanner = () => {
    const { visible, handleDeclineAll, handleAcceptAll } = useCookieBanner();

    return (
        <Modal isOpen={visible} onClose={() => {}} hideCloseButton title={'Cookies'}>
            <ModalBody>
                <Article>
                    <P>
                        We use strictly necessary cookies when you visit our website to give you the best experience
                        possible and to keep things secure.
                    </P>
                    <P>
                        We'd also like your consent to set other cookies to help us improve our website and to tailor
                        the marketing you see.
                    </P>
                </Article>
            </ModalBody>
            <StyledModalFooter>
                <Button variant="outline" onClick={handleDeclineAll}>
                    Decline All Cookies
                </Button>
                <Button variant="outline" onClick={handleAcceptAll}>
                    Accept All Cookies
                </Button>
                <StyledLink to="https://www.standardlife.co.uk/cookies" target="_blank" rel="noreferrer">
                    Manage settings
                </StyledLink>
            </StyledModalFooter>
        </Modal>
    );
};
