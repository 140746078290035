import { styled } from '@slal/ui';

export const StyledButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.blue__secondary__80};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    &:disabled,
    &:disabled:hover {
        cursor: not-allowed;
        text-decoration: none;
        opacity: 0.3;
    }
`;
