import { LumpSumOptions } from '~/types';

export const initialValues = {
    lumpSumOptions: {
        taxFreeCash: {
            maximum: '',
            option: '',
            specifiedAmount: '',
        },
    },
};

export const lumpSumOptions = [
    {
        label: 'All of my tax-free cash:',
        value: LumpSumOptions.MAXIMUM_TAX_FREE_CASH,
    },
    {
        label: 'Half of my tax-free cash:',
        value: LumpSumOptions.HALF_TAX_FREE_CASH,
    },
    {
        label: 'Other amount',
        value: LumpSumOptions.SPECIFY,
    },
];
