import { Fragment } from 'react';
import { StyledDescriptionList, StyledDescriptionTerm, StyledDescriptionDetail } from './styles';
import { DescriptionListProps } from './types';

export const DescriptionList = ({ items, ...otherProps }: DescriptionListProps) => {
    return (
        <StyledDescriptionList role="list" {...otherProps}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    <StyledDescriptionTerm>{item.label}</StyledDescriptionTerm>
                    <StyledDescriptionDetail>{item.value}</StyledDescriptionDetail>
                </Fragment>
            ))}
        </StyledDescriptionList>
    );
};
