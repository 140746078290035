import { styled } from '@slal/ui';
import { Skeleton } from '@slal/ui/animations';
import { breakpointUp } from '@slal/ui/helpers';
import { Anchor } from '@slal/ui/navigation';
import { Article, Roundel, relatedFieldIndent } from '~/components';

export const StyledInvestmentPathwayBreakdown = styled.section`
    ${breakpointUp.xl`
        margin-left: ${relatedFieldIndent};
    `}
`;

export const StyledInvestmentChargesWrapper = styled.div`
    ${breakpointUp.md`
        display: flex;
        align-items: baseline;
    `}
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledInvestmentPathwaysFundFactsheet = styled(Article)`
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledFundFactsheetLoading = styled(Skeleton)`
    margin: ${({ theme }) => theme.space[4]};
    width: 140px;
    height: 40px;
`;

export const StyledFundFactsheetLink = styled(Anchor)`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.space[4]};
    text-decoration: none;
    svg {
        margin-right: ${({ theme }) => theme.space[2]};
        &:last-child {
            margin-top: -${({ theme }) => theme.space[2]};
        }
    }
`;

export const RoundelEquals = styled(Roundel)`
    background-color: ${({ theme }) => theme.colors.blue__secondary__80};
    margin: ${({ theme }) => theme.space[4]} 0;
    ${breakpointUp.md`
        margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} 0 0;
        align-self:center;
    `}
`;

export const RoundelMinus = styled(Roundel)`
    background-color: ${({ theme }) => theme.colors.green__70};
    margin: ${({ theme }) => theme.space[4]} 0;
    ${breakpointUp.md`
        margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} 0 0;
        align-self:center;
    `}
`;
