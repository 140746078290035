import { IconProps } from '@slal/ui/icons';

export const FooterBar = ({ title, ...props }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 100 40"
        width="90%"
        height="20px"
        preserveAspectRatio="none"
        {...props}
    >
        <title>{title || 'Footer Bar'}</title>
        <linearGradient id="a" x1={0} x2={90} y1={0} y2={0} gradientUnits="userSpaceOnUse">
            <stop offset="0%" style={{ stopColor: '#FF8A00' }} />
            <stop offset="40%" style={{ stopColor: '#FFBB00' }} />
            <stop offset="100%" style={{ stopColor: '#FFDD00' }} />
        </linearGradient>
        <path
            d="M0 20 H90"
            style={{
                fill: 'none',
                stroke: 'url(#a)',
                strokeWidth: 20,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 10,
                vectorEffect: 'non-scaling-stroke',
            }}
        />
    </svg>
);
