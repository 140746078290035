import { css, styled } from '@slal/ui';
import { Button } from '@slal/ui/buttons';

export const StyledNav = styled.nav``;

export const StyledList = styled.ul``;

export const StyledListItem = styled.li`
    display: inline-flex;
    list-style: none;
    margin: 0 ${({ theme }) => theme.space[4]};
    padding: 0;

    &:last-child {
        margin: 0;
    }
`;

const neutralButtonProps = css`
    background: none !important;
    border: none !important;
    color: ${({ theme }) => theme.colors.primaryText} !important;
`;

export const StyledPaginationButton = styled(Button)`
    min-width: 0;
    min-height 0;
    ${neutralButtonProps}
    &:hover, &:active, &:focus, &:focus-active {
        ${neutralButtonProps}
    }
    svg {
    }
`;
