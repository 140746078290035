import { Label, useFormState } from '@slal/ui/forms';
import { get } from 'lodash';
import { FieldError, FieldHelp, FieldProps } from './';
import { FieldWrapper } from './styles';

export const Field = ({
    children,
    hideValidationMessage,
    errorFor,
    helpMessage,
    label,
    labelFor,
    ...otherProps
}: FieldProps) => {
    const { errors } = useFormState();
    const errorPropertyName = labelFor || errorFor;
    const errorMessage = !errorPropertyName ? null : get(errors, errorPropertyName)?.message || null;
    const hasError = hideValidationMessage ? errorMessage !== (null || hideValidationMessage) : errorMessage !== null;

    return (
        <FieldWrapper
            {...otherProps}
            aria-invalid={hasError}
            aria-errormessage={hasError ? `${errorPropertyName}-error` : undefined}
        >
            {label && (
                <Label htmlFor={labelFor}>
                    {label}
                    {helpMessage && <FieldHelp>{helpMessage}</FieldHelp>}
                </Label>
            )}
            {hasError && typeof errorMessage === 'string' && (
                <FieldError id={`${labelFor}-error`} errorMessage={errorMessage} />
            )}
            {children}
        </FieldWrapper>
    );
};
