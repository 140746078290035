import { InvestmentPathwayOption } from '~/types';

export const options = [
    {
        label: 'I have no plans to take my money in the next 5 years',
        value: InvestmentPathwayOption.OPTION_1,
    },
    {
        label: 'I plan to use my money to set up a guaranteed income (annuity) within the next 5 years',
        value: InvestmentPathwayOption.OPTION_2,
    },
    {
        label: 'I plan to start taking my money as long-term income within the next 5 years',
        value: InvestmentPathwayOption.OPTION_3,
    },
    {
        label: 'I plan to take all my money out within the next 5 years',
        value: InvestmentPathwayOption.OPTION_4,
    },
];
