import { MainPlanOptions } from '~/types';

export const mainplanFormInitialValues = {
    keyConsiderations: {
        mainPlan: '',
    },
};

export const mainPlanOptions = [
    {
        label: 'Spend it',
        value: MainPlanOptions.SPEND,
    },
    {
        label: 'Reduce debt with it',
        value: MainPlanOptions.REDUCE_DEBT,
    },
    {
        label: 'Live on it',
        value: MainPlanOptions.LIVE,
    },
    {
        label: 'Save or invest it',
        value: MainPlanOptions.SAVE,
    },
];
