import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { P, Text } from '@slal/ui/typography';
import { Link } from 'react-router-dom';

export const StyledPhoneNumber = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes[6]};
`;

export const StyledOpeningHours = styled.div`
    margin: ${({ theme }) => theme.space[4]} 0 0;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
    p {
        margin: 0;
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
`;

export const StyledApplicationButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${({ theme }) => theme.space[7]};

    button {
        width: 100%;
        margin-top: ${({ theme }) => theme.space[5]};
        &:first-child {
            margin-top: 0;
        }
    }

    ${breakpointUp.sm`    
        gap: ${({ theme }) => theme.space[4]};
        button {
            width: auto;
            margin-top: 0;
        }
    `}
`;

export const StyledStartApplicationButtonsWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
`;

const estimatedTimescalesWidth = '120px';

export const StyledStartApplicationLink = styled(Link)`
    display: flex;
    flex: 0 1 calc(100vw - ${estimatedTimescalesWidth});
    text-decoration: none;
    ${breakpointUp.sm`
        flex: 0 1 200px;    
    `}
`;

export const EstimatedCompletionTime = styled(Text)`
    display: flex;
    flex: 1 1 ${estimatedTimescalesWidth};
    color: ${({ theme }) => theme.colors.neutral__dark__20};
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.space[4]};
    margin-left: ${({ theme }) => theme.space[5]};
    ${breakpointUp.sm`
        justify-content: flex-start;
    `}
`;

export const StyledHelpPhoneNumber = styled(P)`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
    padding-top: ${({ theme }) => theme.space[4]};
    font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.regular};
`;
