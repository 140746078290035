import { mainContentId } from '../utils';
import { SkipLink, StyledContainer, StyledHeader, StyledLogo } from './styles';

export const Header = () => {
    return (
        <StyledHeader>
            <StyledContainer>
                <SkipLink href={`#${mainContentId}`}>Skip to main content</SkipLink>
                <StyledLogo variant="dark" />
            </StyledContainer>
        </StyledHeader>
    );
};
