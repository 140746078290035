import { useMutation } from '@apollo/client';
import { useForm } from '@slal/ui/forms';
import { attempt, get, isError } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FieldValues, UseFormProps, DefaultValues } from 'react-hook-form';
import { ApplicationFormSubmitHandler, UseApplicationFormReturn } from '~/features';
import { CreateApplication } from '~/graphql';
import {
    ApplicationSchema,
    ApplicationBackRouteKeys,
    ApplicationFormKeys,
    InvestmentPathwayOptionSchema,
    PensionSchema,
    RegularIncomeSchema,
    CashLikeFundDeclarationSchema,
    InvestmentPathwayOption,
    CompletePersonalInformationSchema,
    ApplicationContextProps,
} from '~/types';
import { useSelectedInvestmentPathway } from './funds';

export const APPLICATION_KEY = 'APPLICATION';

export const useApplication = <FormValues>() => {
    const setSession = useCallback((values: object) => {
        const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
        const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

        if (isError(application)) {
            console.error('Error parsing JSON from session storage');
            return;
        }

        const updatedApplication = { ...application, ...values };

        sessionStorage.setItem(APPLICATION_KEY, JSON.stringify(updatedApplication));
    }, []);

    const getSession = useCallback(
        (formKey: ApplicationFormKeys): (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined => {
            const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
            const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

            if (isError(application)) {
                console.error('Error parsing JSON from session storage');
                return undefined;
            }

            const formValues = get(application, formKey);
            if (!formValues) return undefined;

            return {
                [formKey]: formValues,
            } as (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined;
        },
        []
    );

    return {
        setSession,
        getSession,
    };
};

export const useApplicationForm = <FormValues extends FieldValues = FieldValues>(
    options: UseFormProps<FormValues>,
    formKey: ApplicationFormKeys
): UseApplicationFormReturn<FormValues> => {
    const { getSession, setSession } = useApplication<FormValues>();

    useEffect(() => {
        const initialValuesFromStorage = getSession(formKey);
        if (!initialValuesFromStorage) {
            setSession(options.defaultValues!);
        }
    }, []);

    const baseFormOptions: UseFormProps<FormValues> = {
        mode: 'onTouched',
        resetOptions: {
            keepDirty: false,
            keepErrors: false,
            keepTouched: true,
        },
    };
    const sessionValues = getSession(formKey) || options.defaultValues;

    const form = useForm({ ...baseFormOptions, ...options, defaultValues: sessionValues });

    const handleSubmit: ApplicationFormSubmitHandler = useCallback(
        onValidCallback => async event => {
            event.preventDefault();
            const valid = await form.trigger();
            const formValues: FormValues = form.getValues();
            setSession(formValues);
            if (valid && onValidCallback) onValidCallback();
        },
        [setSession, form]
    );
    return {
        form,
        handleSubmit,
    };
};

export const ApplicationContext = createContext<ApplicationContextProps | undefined>(undefined);

export const useApplicationContext = (): ApplicationContextProps => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error('useApplicationContext must be used within an ApplicationContextProvider');
    }
    return context;
};

export const useApplicationInvestmentPathwayFundCode = () => {
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.INVESTMENT_PATHWAY_OPTIONS);
    if (!application) return undefined;
    const {
        investmentPathway: { fundCode },
    } = application as InvestmentPathwayOptionSchema;
    return fundCode as InvestmentPathwayOption;
};

export const useApplicationSessionBack = (key: ApplicationBackRouteKeys) => {
    const { investmentOption, withdrawalOption } = useApplicationContext();

    switch (key) {
        case 'investment-options':
            return withdrawalOption === 'Regular income' || withdrawalOption === 'Both'
                ? '/application/regular-income'
                : '/application/lump-sum-options';

        case 'key-considerations-information':
            return investmentOption === 'custom'
                ? '/application/fund-options'
                : '/application/investment-pathway-options';

        default:
            console.error('Unknown route key:', key);
            return '/';
    }
};

const useApplicationData = <Schema>(key: ApplicationFormKeys): Schema | undefined => {
    const { getSession } = useApplication();
    const application = getSession(key);
    if (!application) return undefined;
    return application as Schema;
};

export const useApplicationPensions = () => {
    const application = useApplicationData<PensionSchema>(ApplicationFormKeys.PENSION_TRANSFER);
    return application?.pensions;
};

export const useApplicationPersonalInformation = () => {
    const application = useApplicationData<CompletePersonalInformationSchema>(ApplicationFormKeys.PERSONAL_INFORMATION);
    return application?.personalInformation;
};

export const useRegularIncome = () => {
    const application = useApplicationData<RegularIncomeSchema>(ApplicationFormKeys.REGULAR_INCOME);
    return application?.regularIncome;
};

export const useCashLikeFundDeclaration = () => {
    const application = useApplicationData<CashLikeFundDeclarationSchema>(
        ApplicationFormKeys.CASH_LIKE_FUND_DECLARATION
    );
    return application?.cashLikeFundDeclaration?.consent;
};

export const useInvestmentPathwayCharges = (fundCode: InvestmentPathwayOption) => {
    const {
        pensionValues: { total, withdrawals },
        withdrawalOption,
    } = useApplicationContext();
    const { investmentPathway, loading } = useSelectedInvestmentPathway(fundCode);
    const regularIncome = useRegularIncome();

    const totalExpenseRate = !loading ? investmentPathway?.totalExpenseRate?.toFixed(3) : '';

    const regularIncomeMonthlyValue = Number(regularIncome?.amount);
    const lumpSumValue = Number(withdrawals?.lumpSum);

    const calculateRemainingPensionValue = () => {
        let remainingPensionValue = total;

        switch (withdrawalOption) {
            case 'Regular Income':
                remainingPensionValue -= regularIncomeMonthlyValue;
                break;
            case 'Lump sum':
                remainingPensionValue -= lumpSumValue;
                break;
            case 'Both':
                remainingPensionValue -= lumpSumValue + regularIncomeMonthlyValue;
                break;
            default:
                break;
        }

        return remainingPensionValue;
    };

    const remainingPensionValue = calculateRemainingPensionValue();
    const discount = remainingPensionValue < 25000 ? 0.3 : 0.5;

    return {
        discount,
        totalExpenseRate,
        totalCharge: !loading && totalExpenseRate ? (parseFloat(totalExpenseRate) - discount).toFixed(3) : '',
        loading,
    };
};

export const useCommunicationPreferencesModal = () => {
    const { getSession } = useApplication();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    const getCommunicationPreferences = () => {
        const application = getSession(ApplicationFormKeys.COMMUNICATION_PREFERENCES);
        return (
            application?.communicationPreferences || {
                email: true,
                phone: true,
                text: true,
            }
        );
    };

    return {
        isModalOpen,
        openModal,
        closeModal,
        getCommunicationPreferences,
    };
};

export const useCreateApplicationMutation = () => {
    const [createApplication, { data, error, loading }] = useMutation(CreateApplication, {
        onError: console.log,
    });

    return {
        createApplication,
        data: data?.createApplication || null,
        error: !!error,
        loading,
    };
};
