import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { ListFunds, ListInvestmentPathways } from '~/graphql';
import {
    ApplicationFormKeys,
    BaseFund,
    Fund,
    InvestmentPathwayOption,
    UseBaseGraphQlResponse,
    UseFundSelectorResponse,
    UseListFundsResponse,
    UseSelectedInvestmentPathwayResponse,
} from '~/types';
import { useApplication, useCashLikeFundDeclaration } from './application';

export const useListFunds = (): UseListFundsResponse => {
    const { data, error, loading } = useQuery(ListFunds);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const pageLength = 10;

    const funds = useMemo(() => data?.listFunds || [], [data]);
    const setPageCallback = useCallback((page: number) => setPage(page), [setPage]);

    const filteredFunds = useMemo(() => {
        if (!searchTerm) return funds;
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        return funds.filter(
            (fund: Fund) =>
                fund.fundName.toLowerCase().includes(lowercasedSearchTerm) ||
                fund.fundCode.toLowerCase().includes(lowercasedSearchTerm)
        );
    }, [searchTerm, funds]);

    const paginatedFunds = useMemo(() => {
        const startSlice = (page - 1) * pageLength;
        return filteredFunds.slice(startSlice, startSlice + pageLength);
    }, [page, filteredFunds]);

    const pagination = {
        current: page,
        next: page * pageLength < filteredFunds.length ? page + 1 : null,
        previous: page - 1 || null,
        setPage: setPageCallback,
    };

    return {
        error,
        funds: paginatedFunds,
        loading,
        pagination,
        setSearchTerm,
    };
};

export const useSelectedInvestmentPathway = (
    investmentPathwayOption?: InvestmentPathwayOption
): UseSelectedInvestmentPathwayResponse => {
    const { data, error, loading } = useQuery(ListInvestmentPathways);

    const investmentPathways = data?.listInvestmentPathways;

    const investmentPathway = investmentPathways?.find(
        ({ fundCode }: BaseFund) => fundCode === investmentPathwayOption
    );

    return {
        investmentPathway,
        loading,
        error,
    };
};

export const useFundSelector = (): UseFundSelectorResponse => {
    const { getSession, setSession } = useApplication();
    const [application, setApplication] = useState(getSession(ApplicationFormKeys.FUND_ALLOCATIONS));

    const handleSelect = useCallback(
        (fund: Fund) => {
            if (application?.fundAllocations?.find(({ fundCode }) => fundCode === fund.fundCode)) {
                setSession({
                    ...application,
                    fundAllocations: application.fundAllocations.filter(({ fundCode }) => fundCode !== fund.fundCode),
                });
            } else {
                setSession({
                    ...application,
                    fundAllocations: !application?.fundAllocations?.length
                        ? [fund]
                        : [...application.fundAllocations, ...[fund]],
                });
            }
            setApplication(getSession(ApplicationFormKeys.FUND_ALLOCATIONS));
        },
        [getSession, setApplication, setSession, application]
    );

    if (!application?.fundAllocations?.length)
        return {
            handleSelect,
        };

    const { fundAllocations } = application;

    return {
        fundAllocations,
        handleSelect,
    };
};

export const useFundByFundCode = (fundCode: Fund['fundCode']): { fund: Fund } & UseBaseGraphQlResponse => {
    const { data, error, loading } = useQuery(ListFunds);
    return {
        error,
        fund: data?.listFunds?.find((fund: Fund) => fund.fundCode === fundCode) || null,
        loading,
    };
};

export const useFundAllocationsIncludeCashlikeFund = (
    fundAllocations: UseFundSelectorResponse['fundAllocations']
): { includesCashLikeFund: boolean; requiresCashLikeWarningConfirmation: boolean } => {
    const cashLikeFundDeclaration = useCashLikeFundDeclaration();

    if (!fundAllocations?.length) return { includesCashLikeFund: false, requiresCashLikeWarningConfirmation: false };

    const cashLikeFundAllocations = fundAllocations.filter(({ cashLikeFund }) => cashLikeFund == true);

    if (!cashLikeFundAllocations.length)
        return { includesCashLikeFund: false, requiresCashLikeWarningConfirmation: false };

    const totalCashLikeAllocation = cashLikeFundAllocations.reduce(
        (totalCashLikeAllocation, { allocation }) => totalCashLikeAllocation + Number(allocation),
        0
    );
    const requiresCashLikeWarningConfirmation = totalCashLikeAllocation > 50 && !cashLikeFundDeclaration;
    return { includesCashLikeFund: true, requiresCashLikeWarningConfirmation };
};
