import { Col, Container, Row } from '@slal/ui/layout';
import { Heading, P } from '@slal/ui/typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronIcon } from './ChevronIcon';
import {
    Accordion,
    AccordionPanelBox,
    Copyright,
    FooterBar,
    FooterLink,
    FooterLinkList,
    FooterLinkListItem,
    FooterLinks,
    FooterLinkWrapper,
    LinkGroup,
    Logo,
    SecondaryFooter,
    Strapline,
    StyledCol,
    StyledHr,
    ToggleButton,
    FooterWrapper,
} from './styles';
export const Footer = () => {
    const year = new Date().getFullYear();
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <FooterWrapper>
            <FooterBar title="Footer Bar" />

            <Container>
                <Row>
                    <StyledCol col={12} md={6}>
                        <Logo role="presentation" variant="dark" />
                        <Strapline>Part of the Phoenix Group</Strapline>
                        <Strapline>
                            Proud to be part of the UK's largest long-term savings and retirement business
                        </Strapline>
                    </StyledCol>
                    <StyledCol col={12} md={6}>
                        <FooterLinkWrapper>
                            <LinkGroup aria-labelledby="help-and-support-link-group-title">
                                <Heading id="help-and-support-link-group-title" level={5}>
                                    Help and Support
                                </Heading>
                                <FooterLink
                                    to="https://www.standardlife.co.uk/contact-us"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Contact us
                                </FooterLink>
                            </LinkGroup>
                            <LinkGroup aria-labelledby="online-services-link-group-title">
                                <Heading id="online-services-link-group-title" level={5}>
                                    Online services
                                </Heading>
                                <FooterLink
                                    to="https://online.standardlife.com/secure/customer-authentication-client/customer/login"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Login
                                </FooterLink>
                            </LinkGroup>
                        </FooterLinkWrapper>
                    </StyledCol>
                </Row>

                <StyledHr />

                <FooterLinks>
                    <FooterLinkList>
                        <FooterLinkListItem>
                            <FooterLink
                                to="https://www.standardlife.co.uk/help/accessibility"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Accessibility
                            </FooterLink>
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <FooterLink to="https://www.standardlife.co.uk/legal" target="_blank" rel="noreferrer">
                                Legal information
                            </FooterLink>
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <FooterLink to="https://www.standardlife.co.uk/privacy" target="_blank" rel="noreferrer">
                                Privacy Policy
                            </FooterLink>
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <FooterLink to="https://www.standardlife.co.uk/cookies" target="_blank" rel="noreferrer">
                                Cookie Policy
                            </FooterLink>
                        </FooterLinkListItem>
                    </FooterLinkList>
                </FooterLinks>
            </Container>

            <SecondaryFooter>
                <Container>
                    <Row>
                        <Col col={12} lg={7} xl={6}>
                            <Accordion>
                                <ToggleButton
                                    onClick={toggleAccordion}
                                    open={isOpen}
                                    aria-labelledby="website-and-provider-details-button-title"
                                >
                                    <ChevronIcon title="Chevron Icon" />
                                    <Heading level={6} id="website-and-provider-details-button-title">
                                        Website and provider details
                                    </Heading>
                                </ToggleButton>
                                {isOpen && (
                                    <AccordionPanelBox>
                                        <P>
                                            This website describes products and services provided by Phoenix Life
                                            Limited, trading as Standard Life.
                                        </P>

                                        <P>
                                            Phoenix Life Limited, trading as Standard Life, is registered in England and
                                            Wales (1016269) at 1 Wythall Green Way, Wythall, Birmingham, B47 6WG.
                                        </P>

                                        <P>
                                            Phoenix Life Limited is authorised by the Prudential Regulation Authority
                                            and regulated by the Financial Conduct Authority and the Prudential
                                            Regulation Authority.
                                        </P>

                                        <P>
                                            Phoenix Life Limited uses the Standard Life brand, name and logo, under
                                            licence from Phoenix Group Management Services Limited.
                                        </P>

                                        <P>
                                            You can{' '}
                                            <Link
                                                to="https://www.standardlife.co.uk/legal#company-and-regulation-details"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                find out more information
                                            </Link>{' '}
                                            about the companies providing the products and services.
                                        </P>

                                        <P>
                                            &copy;&nbsp;{year} Phoenix Group Management Services Limited. All rights
                                            reserved.
                                        </P>
                                    </AccordionPanelBox>
                                )}
                            </Accordion>
                        </Col>
                        <Col col={12} lg={5} xl={6}>
                            <Copyright>
                                &copy;&nbsp;{year} Phoenix Group Management Services Limited. All rights reserved.
                            </Copyright>
                        </Col>
                    </Row>
                </Container>
            </SecondaryFooter>
        </FooterWrapper>
    );
};
