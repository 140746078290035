import { useCallback, useEffect, useState } from 'react';
import { StandardLifeCookie, StandardLifeCookieValues } from '~/types';

export const SL_COOKIE_NAME = 'sl#cookiepreferences';

export const getCookie = (cname: string): string | null => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
};

const useStandardLifeCookie = () => {
    const [listening, setlistening] = useState(false);
    const [standardLifeCookie, setStandardLifeCookie] = useState<StandardLifeCookie>(getCookie(SL_COOKIE_NAME));

    const cookieUpdater = useCallback(() => {
        setInterval(() => {
            setStandardLifeCookie(getCookie(SL_COOKIE_NAME));
        }, 500);
    }, [setStandardLifeCookie]);

    useEffect(() => {
        if (listening) return;
        cookieUpdater();
        setlistening(true);
    }, [listening, cookieUpdater]);

    return standardLifeCookie;
};

export const useCookieValues = (): StandardLifeCookieValues => {
    const standardLifeCookie = useStandardLifeCookie();

    const getCookieValues = (): StandardLifeCookieValues => {
        if (!standardLifeCookie)
            return {
                insights: false,
                marketing: false,
                standardLifeCookie,
            };
        const { cookiecategoryids } = JSON.parse(standardLifeCookie);
        const categoryids = cookiecategoryids.split('|');
        return {
            insights: categoryids.includes('2'),
            marketing: categoryids.includes('3'),
            standardLifeCookie,
        };
    };

    return getCookieValues();
};
