import { styled } from '@slal/ui';
import { Label } from '@slal/ui/forms';
import { breakpointUp } from '@slal/ui/helpers';
import { Col } from '@slal/ui/layout';
import { ModalBody, ModalFooter } from '@slal/ui/modal';

export const StyledModalBody = styled(ModalBody)`
    width: 100%;
    [role='group'] {
        label {
            &:first-child {
                min-width: 44px;
            }
        }
    }

    ${breakpointUp.sm`
    [role='group'] {
        justify-content: flex-end;
    }
    `}
`;

export const StyledModalFooter = styled(ModalFooter)`
    padding: calc(16px + 1px) 0;
    justify-content: flex-start;
`;

export const StyledCol = styled(Col)`
    margin-top: ${({ theme }) => theme.space[5]};
    ${breakpointUp.sm`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 0;
    `}
`;

export const StyledLabel = styled(Label)`
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
`;
