import { PropsWithChildren } from 'react';
import { CookieBanner } from '../CookieBanner';
import { Footer } from '../Footer';
import { Header } from '../Header';

export const Layout = ({ children }: PropsWithChildren) => (
    <>
        <Header />
        {children}
        <Footer />
        <CookieBanner />
    </>
);
