import { FundAllocation } from './funds';

export enum ApplicationFormKeys {
    ADVICE = 'advice',
    BANK_DETAILS = 'bankDetails',
    CASH_LIKE_FUND_DECLARATION = 'cashLikeFundDeclaration',
    COMMUNICATION_PREFERENCES = 'communicationPreferences',
    DECLARATION = 'declaration',
    FUND_ALLOCATIONS = 'fundAllocations',
    KEY_CONSIDERATIONS = 'keyConsiderations',
    LUMP_SUM_OPTIONS = 'lumpSumOptions',
    INVESTMENT_OPTIONS = 'investment',
    INVESTMENT_PATHWAY_OPTIONS = 'investmentPathway',
    PENSION_CONSOLIDATION = 'pensionConsolidation',
    PENSION_DECLARATION = 'pensionDeclaration',
    PENSION_TRANSFER = 'pensions',
    PERSONAL_INFORMATION = 'personalInformation',
    REGULAR_INCOME = 'regularIncome',
    WITHDRAWAL_OPTIONS = 'withdrawal',
}

export type PersonalInformationFormValues = {
    title: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    emailAddress: string;
    phoneNumber: string;
};

export type PersonalInformationSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: PersonalInformationFormValues;
};

export type AdditionalDetailsFormValues = {
    addressLineOne: string;
    addressLineTwo: string;
    addressLineThree?: string;
    postcode: string;
    employmentStatus: string;
    maritalStatus: string;
    nationalInsuranceNumber: string;
};

export type AdditionalDetailsSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: AdditionalDetailsFormValues;
};

export type CompletePersonalInformationValues = PersonalInformationFormValues & AdditionalDetailsFormValues;

export type CompletePersonalInformationSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: CompletePersonalInformationValues;
};

export type Pension = {
    policyNumber: string;
    provider: string;
    value: number | string;
    moneyWithdrawn: string;
    employerContribution: string;
};

export type PensionSchema = {
    [ApplicationFormKeys.PENSION_TRANSFER]: Pension[] | [];
};

export type PensionDeclarationFormValues = {
    declaration: boolean;
};

export type PensionDeclarationSchema = {
    [ApplicationFormKeys.PENSION_DECLARATION]: PensionDeclarationFormValues;
};

export type AdviceFormValues = {
    adviceReceived: string;
};

export type AdviceSchema = {
    [ApplicationFormKeys.ADVICE]: AdviceFormValues;
};

export type OptionsFormValues = {
    option: string;
};

export type WithdrawalOptionsSchema = {
    [ApplicationFormKeys.WITHDRAWAL_OPTIONS]: OptionsFormValues;
};

export enum LumpSumOptions {
    MAXIMUM_TAX_FREE_CASH = 'MAXIMUM_TAX_FREE_CASH',
    HALF_TAX_FREE_CASH = 'HALF_TAX_FREE_CASH',
    SPECIFY = 'SPECIFY',
}

export type LumpSumOptionsFormValues = {
    option: LumpSumOptions | string;
    specifiedAmount?: number | string;
};

export type LumpSumOptionsSchema = {
    [ApplicationFormKeys.LUMP_SUM_OPTIONS]: LumpSumOptionsFormValues;
};

export type ApplicationPensionWithdrawalValues = {
    lumpSumOption?: LumpSumOptions | string;
    lumpSum?: number;
    regularIncome?: number;
    regularIncomePaymentDay?: string;
};

export type ApplicationPensionValues = {
    halfTaxFreeCash: number;
    maximumLumpSum: number;
    maximumRegularIncome: number;
    maximumTaxFreeCash: number;
    maximumTaxFreeCashTaken: boolean;
    remainderAfterLumpSum?: number;
    total: number;
    withdrawals?: ApplicationPensionWithdrawalValues;
};

export enum TaxFreeCashLevel {
    NONE = 'NONE',
    UNDER = 'UNDER',
    OVER = 'OVER',
    MAX = 'MAX',
}

export type InvestmentOptionSchema = {
    [ApplicationFormKeys.INVESTMENT_OPTIONS]: OptionsFormValues;
};

export enum InvestmentPathwayOption {
    OPTION_1 = 'KLIB',
    OPTION_2 = 'NJEE',
    OPTION_3 = 'LCKK',
    OPTION_4 = 'GBMC',
}

export type InvestmentPathwayOptionFormValues = {
    fundCode: string;
    fundName: string;
};

export type InvestmentPathwayOptionSchema = {
    [ApplicationFormKeys.INVESTMENT_PATHWAY_OPTIONS]: InvestmentPathwayOptionFormValues;
};

export type RegularIncomeFormValues = {
    amount: number | string;
    day: number | string;
};

export type RegularIncomeSchema = {
    [ApplicationFormKeys.REGULAR_INCOME]: RegularIncomeFormValues;
};

export type FundAllocationsSchema = {
    [ApplicationFormKeys.FUND_ALLOCATIONS]?: FundAllocation[];
};

export enum MainPlanOptions {
    SPEND = 'Spend it',
    REDUCE_DEBT = 'Reduce debt with it',
    LIVE = 'Live on it',
    SAVE = 'Save or invest it',
}

export type KeyConsiderationsSchema = {
    [ApplicationFormKeys.KEY_CONSIDERATIONS]: {
        [key: string]: string;
    };
};

export type ApplicationBackRouteKeys = 'investment-options' | 'key-considerations-information';

export type BankDetailsFormValues = {
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
    buildingSocietyRollNumber?: string;
    sortCode: string;
};

export type BankDetailsSchema = {
    [ApplicationFormKeys.BANK_DETAILS]: BankDetailsFormValues;
};

export type CommunicationPreferencesFormValues = {
    email: boolean;
    phone: boolean;
    text: boolean;
};

export type CommunicationPreferencesSchema = {
    [ApplicationFormKeys.COMMUNICATION_PREFERENCES]: CommunicationPreferencesFormValues;
};

export type DeclarationFormValues = {
    consent: boolean;
};

export type DeclarationSchema = {
    [ApplicationFormKeys.DECLARATION]: DeclarationFormValues;
};

export type CashLikeFundDeclarationSchema = {
    [ApplicationFormKeys.CASH_LIKE_FUND_DECLARATION]: DeclarationFormValues;
};

// Keep this up to date with all schema types
export type ApplicationSchema = AdviceSchema &
    BankDetailsSchema &
    CommunicationPreferencesSchema &
    CompletePersonalInformationSchema &
    DeclarationSchema & {
        [ApplicationFormKeys.FUND_ALLOCATIONS]?: FundAllocation[];
    } & InvestmentOptionSchema & {
        [ApplicationFormKeys.INVESTMENT_PATHWAY_OPTIONS]: InvestmentPathwayOptionFormValues | object;
    } & KeyConsiderationsSchema & {
        [ApplicationFormKeys.LUMP_SUM_OPTIONS]: LumpSumOptionsFormValues | object;
    } & PensionSchema & {
        [ApplicationFormKeys.REGULAR_INCOME]: RegularIncomeFormValues | object;
    } & WithdrawalOptionsSchema;

export type CreateApplicationResponse = {
    estimatedTimescales: string | null;
    nextSteps: string[];
};

export type ApplicationContextProps = {
    withdrawalOption?: string;
    investmentOption?: string;
    pensionValues: ApplicationPensionValues;
};

export type ApplicationPensionValuesInput = {
    pensions?: ApplicationSchema[ApplicationFormKeys.PENSION_TRANSFER];
    lumpSumOptions?: ApplicationSchema[ApplicationFormKeys.LUMP_SUM_OPTIONS];
    regularIncome?: RegularIncomeFormValues;
    withdrawal?: ApplicationSchema[ApplicationFormKeys.WITHDRAWAL_OPTIONS];
};
