import { StandardLifeLogo } from '@slal/ui/brand';
import { breakpointUp } from '@slal/ui/helpers';
import { Col } from '@slal/ui/layout';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FooterBar as uiFooterBar } from './FooterBar';

export const FooterWrapper = styled.footer`
    background-color: ${({ theme }) => theme.colors.neutral__light__00};
    margin-top: ${({ theme }) => theme.space[7]};

    ${breakpointUp.md`
        margin-top: ${({ theme }) => theme.space[9]};
    `}
`;

export const FooterBar = styled(uiFooterBar)`
    display: block;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[7]};
`;

export const StyledCol = styled(Col)`
    padding-right: 100px;
`;

export const Logo = styled(StandardLifeLogo)`
    width: 170px;
    height: auto;
    margin: 0;
`;

export const FooterLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[6]};
    padding: ${({ theme }) => theme.space[5]} 0;

    ${breakpointUp.md`
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    `}
`;

export const LinkGroup = styled.aside`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[6]};
    h5 {
        color: ${({ theme }) => theme.colors.blue__primary__70};
    }
`;

export const FooterLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue__secondary__80};

    &:hover {
        color: ${({ theme }) => theme.colors.blue__secondary__60};
    }
`;

export const Strapline = styled.p`
    font-size: ${({ theme }) => theme.space[5]};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue__primary__70};
    margin-bottom: 0;

    &:last-of-type {
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
`;

export const StyledHr = styled.hr`
    border-top: 3px solid ${({ theme }) => theme.colors.neutral__light__30};
    margin: ${({ theme }) => theme.space[6]} 0;
`;

export const FooterLinks = styled.nav``;

export const FooterLinkList = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    ${breakpointUp.md`
        gap: ${({ theme }) => theme.space[7]};
        flex-direction: row;
    `}
`;
export const FooterLinkListItem = styled.li`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const SecondaryFooter = styled.div`
    margin-top: ${({ theme }) => theme.space[7]};
    background-color: ${({ theme }) => theme.colors.neutral__light__10};
    padding: ${({ theme }) => theme.space[6]} 0;
`;

export const Copyright = styled.p`
    color: ${({ theme }) => theme.colors.blue__primary__70};
    margin: 0;
    padding-top: ${({ theme }) => theme.space[5]};
    ${breakpointUp.lg`
         padding-top: 0px;
    `}
`;

export const Accordion = styled.div``;

export const ToggleButton = styled.button.attrs({ type: 'button' })<{ open: boolean }>`
    cursor: pointer;
    background-color: transparent;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.space[4]};
    border: none;
    svg {
        transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
    h6 {
        color: ${({ theme }) => theme.colors.blue__primary__70};
        font-size: ${({ theme }) => theme.fontSizes[4]};
        margin: 0;
    }
`;

export const AccordionPanelBox = styled.article`
    border-left: ${({ theme }) => theme.space[2]} solid ${({ theme }) => theme.colors.yellow__70};
    margin: ${({ theme }) => theme.space[4]} 0 0 ${({ theme }) => theme.space[4]};
    padding: 0 0 0 ${({ theme }) => theme.space[5]};
    color: ${({ theme }) => theme.colors.neutral__dark__10};
    p {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }
    a {
        color: ${({ theme }) => theme.colors.blue__secondary__80};
    }
`;
