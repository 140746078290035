import { styled } from '@slal/ui';
import { Heading, Text } from '@slal/ui/typography';

export const CashLikeFundsWarningHeading = styled(Heading)`
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledDeclarationsLabel = styled(Text)`
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[4]};
`;
