import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_APPLICATION_RESPONSE } from '~/features';
import { useApplication, useFundAllocationsIncludeCashlikeFund } from '~/hooks';
import { ApplicationFormKeys } from '~/types';

const useRedirectRules = (): string | undefined => {
    const { pathname } = useLocation();
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.FUND_ALLOCATIONS);
    const createApplicationResponse = sessionStorage.getItem(CREATE_APPLICATION_RESPONSE);
    const { requiresCashLikeWarningConfirmation } = useFundAllocationsIncludeCashlikeFund(application?.fundAllocations);
    if (!!createApplicationResponse && pathname !== '/application/confirmation') return '/application/confirmation';
    if (requiresCashLikeWarningConfirmation && pathname === '/application/key-considerations/information')
        return '/application/cash-like-funds-warning';
};

export const ApplicationRedirect = () => {
    const navigate = useNavigate();
    const redirectPath = useRedirectRules();
    useEffect(() => {
        if (!redirectPath) return;
        navigate(redirectPath);
    }, [navigate, redirectPath]);

    return null;
};

export default ApplicationRedirect;
