import { PDFIcon } from '@slal/ui/icons';
import { StyledDocumentLink } from './styles';
import { DocumentLinkProps } from './types';

export const DocumentLink = ({ children, ...otherProps }: DocumentLinkProps) => {
    return (
        <StyledDocumentLink target="_blank" {...otherProps}>
            <PDFIcon />
            {children}
        </StyledDocumentLink>
    );
};
