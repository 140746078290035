import { styled } from '@slal/ui';
import { Button } from '@slal/ui/buttons';
import { breakpointUp } from '@slal/ui/helpers';

export const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    padding: 1.5rem 1.5rem;
    border-style: dashed;
    border-width: ${({ theme }) => theme.space[2]};
    margin-bottom: ${({ theme }) => theme.space[6]};
    &:hover {
        border-style: dashed;
        border-width: ${({ theme }) => theme.space[2]};
    }
    min-width: 100%;

    ${breakpointUp.md`
        min-width: 70%
    `}
    ${breakpointUp.lg`
        min-width: 60%
    `}
`;

StyledButton.defaultProps = {
    variant: 'outline',
};
