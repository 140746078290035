import { styled } from '@slal/ui';
import { Table, TD, TFoot } from '@slal/ui/layout';

export const StyledTargetFundAllocation = styled.div<{ level: 'info' | 'danger' }>`
    color: ${({ level, theme }) => (level === 'danger' ? theme.colors.danger : 'inherit')};
`;

export const StyledTable = styled(Table)`
    border: 1px solid ${({ theme }) => theme.colors.neutral__dark__60};
`;

export const StyledTD = styled(TD)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral__dark__60};

    svg {
        vertical-align: middle;
        margin-bottom: 2px;
    }
`;

export const StyledTFoot = styled(TFoot)`
    ${StyledTD} {
        background-color: ${({ theme }) => theme.colors.transparent};
    }
`;

export const StyledFundAllocatinTableWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.space[6]};
`;
