import { styled } from '@slal/ui';
import { CheckCircleIcon, ErrorIcon, InformationIcon, WarningIcon } from '@slal/ui/icons';
import { Card } from '../Card';
import { NotificationProps } from './types';
import { Article } from '../Typography';

export const StyledNotificationCard = styled(Card)<NotificationProps>`
    display: flex;
    margin-bottom: ${({ theme: { space } }) => space[7]};
    border-left-width: ${({ theme, level }) => (level === 'success' ? 'inherit' : theme.space[3])};
    border-color: ${({ theme, level }) =>
        level === 'danger'
            ? theme.colors.red__80
            : level === 'warning'
              ? theme.colors.orange__80
              : level === 'success'
                ? theme.colors.green__00
                : theme.colors.blue__secondary__80};
    background-color: ${({ theme, level }) =>
        level === 'danger'
            ? theme.colors.red__00
            : level === 'warning'
              ? theme.colors.orange__00
              : level === 'success'
                ? theme.colors.green__00
                : theme.colors.blue__secondary__00};
`;

export const StyledNotificationContent = styled(Article)`
    margin-left: ${({ theme: { space } }) => space[4]};
    padding-top: ${({ theme: { space } }) => space[2]};

    h5 {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }

    p {
        &:last-child {
            margin-bottom: ${({ theme }) => theme.space[4]};
        }
    }
`;

export const StyledDangerIcon = styled(ErrorIcon)`
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme: { colors } }) => colors.red__80};
`;

export const StyledInformationIcon = styled(InformationIcon)`
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme: { colors } }) => colors.blue__secondary__80};
`;

export const StyledSuccessIcon = styled(CheckCircleIcon)`
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme: { colors } }) => colors.green__80};
`;

export const StyledWarningIcon = styled(WarningIcon)`
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme: { colors } }) => colors.orange__80};
`;
