import { ModalFooter } from '@slal/ui/modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    color: ${({ theme }) => theme.colors.blue__secondary__80};
    padding: ${({ theme }) => theme.space[3]} 0;

    &:hover {
        color: ${({ theme }) => theme.colors.blue__secondary__60};
    }
`;

export const StyledModalFooter = styled(ModalFooter)`
    flex-direction: column;
    gap: ${({ theme }) => theme.space[5]};
    button {
        width: 100%;
        font-size: ${({ theme }) => theme.fontSizes[4]};
    }
`;
