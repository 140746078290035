import * as yup from 'yup';
import {
    AdditionalDetailsSchema,
    AdviceSchema,
    ApplicationSchema,
    BankDetailsSchema,
    CashLikeFundDeclarationSchema,
    CommunicationPreferencesSchema,
    DeclarationSchema,
    FundAllocation,
    FundAllocationsSchema,
    InvestmentOptionSchema,
    InvestmentPathwayOption,
    InvestmentPathwayOptionSchema,
    KeyConsiderationsSchema,
    LumpSumOptions,
    LumpSumOptionsSchema,
    Pension,
    PensionDeclarationSchema,
    PersonalInformationSchema,
    RegularIncomeSchema,
    WithdrawalOptionsSchema,
} from '~/types';
import { formatToGBP } from '~/utils';

export const eligibleAge = 55;

export const validationMessages = {
    age: `You must be age ${eligibleAge} to access your pension (rising to 57 in 2028)`,
    date: 'A valid date is required',
    email: 'A valid email address is required',
    fundAllocationRequired: 'You must select your fund options',
    fundAllocationValue: 'One or more of your percentage values is not a number between 1 and 100',
    fundAllocationsTotal: 'The total fund allocation must equal 100%',
    fundAllocationsMax: 'You can only invest in 12 funds at any one time',
    pensionValue: 'A value between £1-£99,999,999 is required',
    regularPaymentDay: 'A value between 1 and 28 is required',
    required: 'This is a required field',
    lumpSumValue: 'A value between £1 and your maximum amount is required',
    notYesValue: 'The value cannot be "yes"',
    sortCode: 'A valid sort code is required',
    name: 'A valid name is required',
    nameMax: 'The value must be a maximum of 35 characters',
    phoneNumber: 'A valid phone number is required',
    phoneNumberMin: 'The value must be a minumum of 11 numbers',
    phoneNumberMax: 'The value must be a maximum of 15 numbers',
    niNumber: 'A valid National Insurance number is required',
    postcode: 'A valid UK postcode is required',
    accountNumber: 'A valid account number is required',
    accountNumberMax: 'The value must be a maximum of 8 numbers',
};

const regexPatterns = {
    name: /^[A-Za-z\s]+$/,
    number: /^\d+$/,
    niNumber: /^(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIQUV])[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]{1}$/,
    postcode: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
};

const advice = yup.object().shape({
    adviceReceived: yup.string().required(validationMessages.required),
});

export const adviceValidationSchema: yup.ObjectSchema<AdviceSchema> = yup.object().shape({
    advice,
});

const personalInformation = yup.object().shape({
    title: yup.string().required(validationMessages.required),
    firstName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    lastName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    dateOfBirth: yup
        .string()
        .test(
            'is-iso-date',
            validationMessages.date,
            (value: string | undefined) => !!value && /(\d{4})-(\d{2})-(\d{2})/.test(value!)
        )
        .test('is-eligible', validationMessages.age, (value: string | undefined) => {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear() - eligibleAge;
            return !!value && new Date(`${value}T00:00`).valueOf() <= new Date(`${yyyy}-${mm}-${dd}T00:00`).valueOf();
        })
        .required(validationMessages.required),
    emailAddress: yup.string().email(validationMessages.email).required(validationMessages.required),
    phoneNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.number, validationMessages.phoneNumber)
        .min(11, validationMessages.phoneNumberMin)
        .max(15, validationMessages.phoneNumberMax),
});

export const personalInformationValidationSchema: yup.ObjectSchema<PersonalInformationSchema> = yup.object().shape({
    personalInformation,
});

const additionalDetails = yup.object().shape({
    addressLineOne: yup.string().required(validationMessages.required),
    addressLineTwo: yup.string().required(validationMessages.required),
    addressLineThree: yup.string().optional(),
    postcode: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.postcode, validationMessages.postcode),
    nationalInsuranceNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.niNumber, validationMessages.niNumber),
    employmentStatus: yup.string().required(validationMessages.required),
    maritalStatus: yup.string().required(validationMessages.required),
});

export const additionalDetailsSchema: yup.ObjectSchema<AdditionalDetailsSchema> = yup.object().shape({
    personalInformation: additionalDetails,
});

const completePersonalInformation = personalInformation.concat(additionalDetails);

export const pensionSchema: yup.ObjectSchema<Pension> = yup.object().shape({
    policyNumber: yup.string().required(validationMessages.required),
    provider: yup.string().required(),
    value: yup
        .number()
        .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
        .required(validationMessages.required)
        .min(1, validationMessages.pensionValue)
        .max(99999999, validationMessages.pensionValue),
    moneyWithdrawn: yup
        .string()
        .required(validationMessages.required)
        .test('is-not-yes', validationMessages.notYesValue, (value: string | undefined) => value !== 'Yes'),
    employerContribution: yup
        .string()
        .required(validationMessages.required)
        .test('is-not-yes', validationMessages.notYesValue, (value: string | undefined) => value !== 'Yes'),
});

const withdrawal = yup.object().shape({
    option: yup.string().required(validationMessages.required),
});

export const withdrawalOptionsValidationSchema: yup.ObjectSchema<WithdrawalOptionsSchema> = yup.object().shape({
    withdrawal,
});

const lumpSumOptions = (maximumLumpSum: number) =>
    yup.object().shape({
        option: yup.string().required(validationMessages.required),
        specifiedAmount: yup.string().when('option', {
            is: (value: string) => value === LumpSumOptions.SPECIFY,
            then: () =>
                yup
                    .number()
                    .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
                    .required(validationMessages.required)
                    .min(1, validationMessages.lumpSumValue)
                    .max(maximumLumpSum, validationMessages.lumpSumValue),
        }),
    });

export const lumpSumOptionsValidationSchema = (maximumLumpSum: number): yup.ObjectSchema<LumpSumOptionsSchema> =>
    yup.object().shape({
        lumpSumOptions: lumpSumOptions(maximumLumpSum),
    });

const investment = yup.object().shape({
    option: yup.string().required(validationMessages.required),
});

export const investmentOptionsValidationSchema: yup.ObjectSchema<InvestmentOptionSchema> = yup.object().shape({
    investment,
});

const investmentPathway = yup.object().shape({
    fundCode: yup.string().oneOf(Object.values(InvestmentPathwayOption)).required(validationMessages.required),
    fundName: yup.string().required(validationMessages.required),
});

export const investmentPathwayOptionsValidationSchema: yup.ObjectSchema<InvestmentPathwayOptionSchema> = yup
    .object()
    .shape({
        investmentPathway,
    });

const regularIncome = (maximumRegularIncome: number) => {
    const validationMessage = `A value between £1 and ${formatToGBP(maximumRegularIncome)} is required so it lasts for a year`;
    return yup.object().shape({
        amount: yup
            .number()
            .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
            .required(validationMessages.required)
            .min(1, validationMessage)
            .max(maximumRegularIncome, validationMessage),
        day: yup
            .number()
            .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
            .required(validationMessages.required)
            .min(1, validationMessages.regularPaymentDay)
            .max(28, validationMessages.regularPaymentDay),
    });
};

export const regularIncomeValidationSchema = (maximumRegularIncome: number): yup.ObjectSchema<RegularIncomeSchema> =>
    yup.object().shape({
        regularIncome: regularIncome(maximumRegularIncome),
    });

const fundAllocations = yup
    .array()
    .min(1, validationMessages.fundAllocationRequired)
    .max(12, validationMessages.fundAllocationsMax)
    .test('fund-allocation-values', validationMessages.fundAllocationValue, (fundAllocations?: FundAllocation[]) => {
        if (!fundAllocations) return false;
        for (const { allocation } of fundAllocations) {
            const value = parseFloat(allocation);
            if (!value || value > 100) return false;
        }
        return true;
    })
    .test('fund-allocation-total', validationMessages.fundAllocationsTotal, (fundAllocations?: FundAllocation[]) => {
        let total = 0;
        if (!fundAllocations) return false;
        for (const { allocation } of fundAllocations) {
            const value = parseFloat(allocation);
            total = total + value;
        }
        return total === 100;
    });

export const fundAllocationValidationSchema: yup.ObjectSchema<FundAllocationsSchema> = yup.object().shape({
    fundAllocations,
});

export const keyConsiderationsValidationSchema = (key: string): yup.ObjectSchema<KeyConsiderationsSchema> =>
    yup.object().shape({
        keyConsiderations: yup.object().shape({
            [key]: yup.string().required(validationMessages.required),
        }),
    });

const bankDetails = yup.object().shape({
    accountHolderName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    sortCode: yup
        .string()
        .required(validationMessages.required)
        .test(
            'is-sort-code',
            validationMessages.sortCode,
            (value: string | undefined) => !!value && /(\d{2})-(\d{2})-(\d{2})/.test(value!)
        ),
    bankName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    accountNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.number, validationMessages.accountNumber)
        .max(8, validationMessages.accountNumberMax),
    buildingSocietyRollNumber: yup.string().optional(),
});

export const bankDetailsValidationSchema: yup.ObjectSchema<BankDetailsSchema> = yup.object().shape({
    bankDetails,
});

const pensionDeclaration = yup.object().shape({
    declaration: yup.boolean().oneOf([true], validationMessages.required).required(),
});

export const pensionDeclarationValidationSchema: yup.ObjectSchema<PensionDeclarationSchema> = yup.object().shape({
    pensionDeclaration,
});

const communicationPreferences = yup.object().shape({
    email: yup.boolean().required(),
    phone: yup.boolean().required(),
    text: yup.boolean().required(),
});

export const communicationPreferencesSchema: yup.ObjectSchema<CommunicationPreferencesSchema> = yup.object().shape({
    communicationPreferences,
});

const declaration = yup.object().shape({
    consent: yup.boolean().oneOf([true], validationMessages.required).required(),
});

export const declarationValidationSchema: yup.ObjectSchema<DeclarationSchema> = yup.object().shape({ declaration });

const cashLikeFundDeclaration = yup.object().shape({
    consent: yup.boolean().oneOf([true], validationMessages.required).required(),
});

export const cashLikeFundDeclarationValidationSchema: yup.ObjectSchema<CashLikeFundDeclarationSchema> = yup
    .object()
    .shape({ cashLikeFundDeclaration });

export const applicationSchema = (
    maximumLumpSum: number,
    maximumRegularIncome: number
): yup.ObjectSchema<ApplicationSchema> =>
    yup
        .object()
        .shape({
            advice,
            personalInformation: completePersonalInformation,
            pensions: yup.array().of(pensionSchema).required(validationMessages.required).max(5),
            pensionDeclaration,
            withdrawal,
            lumpSumOptions: yup.object().when('withdrawal.option', {
                is: (value: string) => value !== 'Regular income',
                then: () => lumpSumOptions(maximumLumpSum),
            }),
            regularIncome: yup.object().when('withdrawal.option', {
                is: (value: string) => value !== 'Lump sum',
                then: () => regularIncome(maximumRegularIncome),
            }),
            investment,
            investmentPathway: yup.object().when('investment.option', {
                is: (value: string) => value === 'default',
                then: () => investmentPathway,
            }),
            fundAllocations: yup.array().when('investment.option', {
                is: (value: string) => value === 'custom',
                then: () => fundAllocations,
            }),
            keyConsiderations: yup.object().shape({
                mainPlan: yup.string().required(validationMessages.required),
                healthIssues: yup.string().required(validationMessages.required),
                investmentScams: yup.string().required(validationMessages.required),
                outstandingDebts: yup.string().required(validationMessages.required),
                meansTestedBenefits: yup.string().required(validationMessages.required),
                continueContributing: yup.string().required(validationMessages.required),
                stillInvested: yup.string().required(validationMessages.required),
                remainingPension: yup.string().required(validationMessages.required),
            }),
            bankDetails,
            communicationPreferences,
            declaration,
        })
        .required(validationMessages.required);
