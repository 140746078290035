import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { useCookieValues } from '~/hooks';
import { StandardLifeCookie } from '~/types';

export interface AnalyticsContextProps {
    pushEvent: (data: Record<string, string>) => void;
    standardLifeCookie: StandardLifeCookie;
}

export const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

const reAssureAMPPEvent = 'ReAssureAMPP';
const eventCategory = 'ReAssure to AMPP application';

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { insights, marketing, standardLifeCookie } = useCookieValues();
    const [insightsInitialised, setInsightsInitialised] = useState<boolean>(false);
    const [marketingInitialised, setMarketingInitialised] = useState<boolean>(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (!marketingInitialised && marketing) {
            TagManager.initialize({
                gtmId: import.meta.env.VITE_MARKETING_GTM_CODE,
            });
            setMarketingInitialised(true);
            return;
        } else if (marketingInitialised && !marketing) {
            setMarketingInitialised(false);
        }
    }, [marketing, marketingInitialised, setMarketingInitialised]);

    useEffect(() => {
        if (!insightsInitialised && insights) {
            TagManager.initialize({
                gtmId: import.meta.env.VITE_EXPERIENCE_AND_INSIGHTS_GTM_CODE,
                events: {
                    ReAssureAMPP: reAssureAMPPEvent,
                },
            });
            setInsightsInitialised(true);
            return;
        } else if (insightsInitialised && !insights) {
            setInsightsInitialised(false);
        }
    }, [insights, insightsInitialised, setInsightsInitialised]);

    const pushEvent = useCallback(
        (data: Record<string, string>) => {
            if (insightsInitialised) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: reAssureAMPPEvent,
                        eventCategory,
                        ...data,
                    },
                });
            }
        },
        [insightsInitialised]
    );

    useEffect(() => {
        pushEvent({ eventAction: pathname, eventLabel: 'Page load' });
    }, [insightsInitialised, pathname]);

    return <AnalyticsContext.Provider value={{ pushEvent, standardLifeCookie }}>{children}</AnalyticsContext.Provider>;
};
