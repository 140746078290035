import { IconProps } from '@slal/ui/icons';

export const ChevronIcon = ({ title, ...props }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="16"
        height="17"
        fill="none"
        viewBox="0 0 16 17"
        preserveAspectRatio="none"
        {...props}
    >
        <title>{title || 'Chevron Icon'}</title>
        <path
            fill="#116998"
            fillRule="evenodd"
            d="M4.423 6.199l-.918.894a.533.533 0 00-.172.395c0 .152.057.284.171.395l4.09 3.982c.115.111.25.167.406.167a.562.562 0 00.405-.167l4.09-3.982a.533.533 0 00.172-.395.533.533 0 00-.171-.395l-.92-.894a.562.562 0 00-.405-.167.561.561 0 00-.405.167L8 8.892 5.234 6.199a.562.562 0 00-.405-.167.562.562 0 00-.406.167zm-3.09 2.5c0-1.21.298-2.325.894-3.347a6.637 6.637 0 012.427-2.426A6.522 6.522 0 018 2.032c1.21 0 2.325.298 3.346.894a6.637 6.637 0 012.427 2.426 6.522 6.522 0 01.894 3.347c0 1.209-.298 2.325-.894 3.346a6.637 6.637 0 01-2.427 2.426A6.522 6.522 0 018 15.365a6.521 6.521 0 01-3.346-.894 6.637 6.637 0 01-2.427-2.426 6.522 6.522 0 01-.894-3.346z"
            clipRule="evenodd"
        ></path>
    </svg>
);
