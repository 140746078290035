import { useMutation } from '@apollo/client';
import { CreatePensionConsolidationRequest } from '~/graphql';

export const useCreatePensionConsolidationMutation = () => {
    const [createPensionConsolidationRequest, { data, error, loading }] = useMutation(
        CreatePensionConsolidationRequest,
        {
            onError: console.log,
        }
    );

    return {
        createPensionConsolidationRequest,
        data: data?.createPensionConsolidationRequest || null,
        error: !!error,
        loading,
    };
};
