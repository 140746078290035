import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { Col, Container } from '@slal/ui/layout';
import { FeatureBannerProps } from './types';
import { Heading } from '@slal/ui/typography';

export const StyledHeadingWrapper = styled.div`
    padding-top: ${({ theme }) => theme.space[9]};
    padding-bottom: ${({ theme }) => theme.space[9]};
    ${breakpointUp.md`
        padding-top: ${({ theme }) => theme.space[10]};
        padding-bottom: ${({ theme }) => theme.space[10]};  
    `}

    background-color: ${({ theme }) => theme.colors.blue__primary__70};
    margin-bottom: ${({ theme }) => theme.space[6]};
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const StyledDefaqtoWrapper = styled(Container)`
    margin-top: -${({ theme }) => theme.space[6]};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.space[5]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral__light__60};
`;

StyledDefaqtoWrapper.defaultProps = {
    fluid: true,
};

export const StyledDefaqtoLogo = styled.img`
    width: 110px;
    height: auto;
    margin-right: ${({ theme }) => theme.space[6]};
`;

export const StyledDefactoHeading = styled(Heading)``;

export const StyledFeatureBannerWrapper = styled(StyledHeadingWrapper)<FeatureBannerProps>`
    min-height: 320px;
    padding-top: ${({ theme }) => theme.space[8]};
    background-image: url(${({ featureBannerImgSrc }) => featureBannerImgSrc}),
        radial-gradient(circle at 65% 70%, #1778b3 10%, #0a2f73 50%);
    background-size: 130%;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-bottom: 250px;

    ${breakpointUp.sm`
        background-size: 110%;
    `}

    ${breakpointUp.md`
        background-size: contain;
        background-position: bottom right;
        padding-top: 0px;
        padding-bottom: 0px;
    `}

    ${breakpointUp.lg`
        background-position: bottom 0 right 15%;
    `}

    ${breakpointUp.xl`
        background-position: bottom 0 right 30%;
    `}
`;

export const FeatureImage = styled('img')`
    position: relative;
    left: auto;
    right: 0;
    top: 0;
`;

export const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
`;

export const StyledFeaturedImgCol = styled(StyledCol)`
    display: none;

    ${breakpointUp.md`
        display: flex;
    `}
`;
