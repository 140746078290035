import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager';
const client = new SecretsManagerClient({});

const secretCache: { [key: string]: Record<string, string> } = {};

export const getSecret = async <TSecret extends object>(secretName: string, useCache: boolean = true) => {
    if (useCache && secretCache[secretName]) {
        return secretCache[secretName] as TSecret;
    }
    try {
        const command = new GetSecretValueCommand({ SecretId: secretName });
        const secret = await client.send(command);
        if (!secret) throw new Error(`Secret ${secretName} not found`);
        const parsed = JSON.parse(secret.SecretString!);
        if (useCache) {
            secretCache[secretName] = parsed;
        }
        return parsed as TSecret;
    } catch (error) {
        const message = `Error getting the secret ${secretName}`;
        console.error(message, error);
        throw Error(message);
    }
};
