import { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Layout, PageLoader } from '~/components';
import { ApplicationContextProvider } from '~/providers';
import ApplicationRedirect from './ApplicationRouteRedirect';

const LandingRoute = lazy(() => import('./LandingRoute'));
const ApplicationAdviceRoute = lazy(() => import('./ApplicationAdviceRoute'));
const ApplicationPersonalInformationRoute = lazy(() => import('./ApplicationPersonalInformationRoute'));
const ApplicationAdditionalDetailsRoute = lazy(() => import('./ApplicationalAdditionalDetailsRoute'));
const ApplicationPensionsRoute = lazy(() => import('./ApplicationPensionsRoute'));
const ApplicationPensionFormRoute = lazy(() => import('./ApplicationPensionFormRoute'));
const ApplicationWithdrawalOptionsRoute = lazy(() => import('./ApplicationWithdrawalOptionsRoute'));
const ApplicationLumpSumOptionsRoute = lazy(() => import('./ApplicationLumpSumOptionsRoute'));
const ApplicationRegularIncomeRoute = lazy(() => import('./ApplicationRegularIncomeRoute'));
const ApplicationInvestmentOptionsRoute = lazy(() => import('./ApplicationInvestmentOptionsRoute'));
const ApplicationInvestmentPathwayOptionsRoute = lazy(() => import('./ApplicationInvestmentPathwayOptionsRoute'));
const ApplicationFundOptionsRoute = lazy(() => import('./ApplicationFundOptionsRoute'));
const ApplicationCashLikeFundsWarningRoute = lazy(() => import('./ApplicationCashLikeFundsWarningRoute'));
const ApplicationKeyConsiderationsHealthIssuesRoute = lazy(
    () => import('./ApplicationKeyConsiderationsHealthIssuesRoute')
);
const ApplicationKeyConsiderationsInvestmentScamsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsInvestmentScamsRoute')
);
const ApplicationKeyConsiderationsOutstandingDebtsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsOutstandingDebtsRoute')
);
const ApplicationKeyConsiderationsMeansTestedBenefitsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsMeansTestedBenefitsRoute')
);
const ApplicationKeyConsiderationsContinueContributingRoute = lazy(
    () => import('./ApplicationKeyConsiderationsContinueContributingRoute')
);
const ApplicationKeyConsiderationsStillInvestedRoute = lazy(
    () => import('./ApplicationKeyConsiderationsStillInvestedRoute')
);
const ApplicationKeyConsiderationsRemainingPensionRoute = lazy(
    () => import('./ApplicationKeyConsiderationsRemainingPensionRoute')
);
const ApplicationKeyConsiderationsInformationRoute = lazy(
    () => import('./ApplicationKeyConsiderationsInformationRoute')
);
const ApplicationKeyConsiderationsMainPlanRoute = lazy(() => import('./ApplicationKeyConsiderationsMainPlanRoute'));
const ApplicationBankDetailsRoute = lazy(() => import('./ApplicationBankDetailsRoute'));
const ApplicationReviewAndConfirmRoute = lazy(() => import('./ApplicationReviewAndConfirmRoute'));
const ApplicationConfirmationRoute = lazy(() => import('./ApplicationConfirmationRoute'));

export const AppRoutes = () => (
    <Suspense fallback={<PageLoader />}>
        <Routes>
            <Route
                element={
                    <Layout>
                        <ApplicationRedirect />
                        <Outlet />
                    </Layout>
                }
            >
                <Route path="/" element={<LandingRoute />} />

                <Route
                    path="/application"
                    element={
                        <ApplicationContextProvider>
                            <Outlet />
                        </ApplicationContextProvider>
                    }
                >
                    <Route path="advice" element={<ApplicationAdviceRoute />} />
                    <Route path="personal-information" element={<ApplicationPersonalInformationRoute />} />
                    <Route path="additional-details" element={<ApplicationAdditionalDetailsRoute />} />
                    <Route path="pensions" element={<ApplicationPensionsRoute />} />
                    <Route path="add-pension" element={<ApplicationPensionFormRoute />} />
                    <Route path="withdrawal-options" element={<ApplicationWithdrawalOptionsRoute />} />
                    <Route path="lump-sum-options" element={<ApplicationLumpSumOptionsRoute />} />
                    <Route path="regular-income" element={<ApplicationRegularIncomeRoute />} />
                    <Route path="investment-options" element={<ApplicationInvestmentOptionsRoute />} />
                    <Route path="investment-pathway-options" element={<ApplicationInvestmentPathwayOptionsRoute />} />
                    <Route path="fund-options" element={<ApplicationFundOptionsRoute />} />
                    <Route path="cash-like-funds-warning" element={<ApplicationCashLikeFundsWarningRoute />} />
                    <Route path="key-considerations">
                        <Route path="information" element={<ApplicationKeyConsiderationsInformationRoute />} />
                        <Route path="main-plan" element={<ApplicationKeyConsiderationsMainPlanRoute />} />
                        <Route path="health-issues" element={<ApplicationKeyConsiderationsHealthIssuesRoute />} />
                        <Route path="investment-scams" element={<ApplicationKeyConsiderationsInvestmentScamsRoute />} />
                        <Route
                            path="outstanding-debts"
                            element={<ApplicationKeyConsiderationsOutstandingDebtsRoute />}
                        />
                        <Route
                            path="means-tested-benefits"
                            element={<ApplicationKeyConsiderationsMeansTestedBenefitsRoute />}
                        />
                        <Route
                            path="continue-contributing"
                            element={<ApplicationKeyConsiderationsContinueContributingRoute />}
                        />
                        <Route path="still-invested" element={<ApplicationKeyConsiderationsStillInvestedRoute />} />
                        <Route
                            path="remaining-pension"
                            element={<ApplicationKeyConsiderationsRemainingPensionRoute />}
                        />
                    </Route>
                    <Route path="bank-details" element={<ApplicationBankDetailsRoute />} />
                    <Route path="review-and-confirm" element={<ApplicationReviewAndConfirmRoute />} />
                    <Route path="confirmation" element={<ApplicationConfirmationRoute />} />
                </Route>
            </Route>
        </Routes>
    </Suspense>
);
