import { css, styled } from '@slal/ui';
import { TBody, TD, TFoot, TH, THead, TR, Table } from '@slal/ui/layout';

const borderWidth = '1px';

const cellBorderStyles = css`
    border-right: ${borderWidth} solid ${({ theme }) => theme.colors.neutral__light__40};
    &:last-child {
        border-right: none;
    }
`;

export const StyledTH = styled(TH)`
    ${cellBorderStyles}
`;

export const StyledTD = styled(TD)`
    ${cellBorderStyles}
`;

export const StyledTR = styled(TR)`
    border-top: ${borderWidth} solid ${({ theme }) => theme.colors.neutral__light__40};
`;

export const StyledTHead = styled(THead)`
    ${StyledTH} {
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};
    }
    ${StyledTR} {
        &:last-child {
            border-bottom: ${borderWidth} solid ${({ theme }) => theme.colors.blue__primary__80};
        }
        ${StyledTH} {
            background-color: ${({ theme }) => theme.colors.blue__secondary__10};
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: left;
            }
        }
    }
`;

export const StyledTBody = styled(TBody)`
    border-bottom: ${borderWidth} solid ${({ theme }) => theme.colors.neutral__light__40};
    ${StyledTD} {
        text-align: center;
        height: 50px;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: left;
        }
    }
`;

export const StyledTFoot = styled(TFoot)``;

export const StyledTable = styled(Table)`
    th {
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};
    }
`;
