import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { CheckCircleIcon } from '@slal/ui/icons';
import { Heading } from '@slal/ui/typography';
import { Article, Li, LinkButton, Ul } from '~/components';

export const StyledProductBenefits = styled(Ul)`
    list-style-type: none;
    margin-top: ${({ theme: { space } }) => space[3]};
    margin-bottom: ${({ theme: { space } }) => space[6]};
    padding-left: ${({ theme: { space } }) => space[7]};
`;

export const StyledListItem = styled.li`
    display: flex;
    gap: ${({ theme: { space } }) => space[4]};

    p {
        margin-top: ${({ theme: { space } }) => space[2]};
        margin-bottom: ${({ theme: { space } }) => space[4]};
    }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
    color: ${({ theme: { colors } }) => colors.green__70};
`;

export const ProductRequirementsHeading = styled(Heading)`
    font-weight: 500;
`;

export const DisclaimerAndDocumentsWrapper = styled.div`
    ${breakpointUp.md`
        display: flex;
        justify-content: space-between;
        gap: ${({ theme: { space } }) => space[8]};   
    `}
`;

export const StyledSection = styled.div`
    flex: 1;
`;
export const ProductDisclaimerSection = styled(StyledSection)`
    padding-top: ${({ theme: { space } }) => space[3]};
`;

export const ProductDocumentSection = styled(StyledSection)``;

export const StyledDocuments = styled(Ul)`
    list-style-type: none;
    padding-left: ${({ theme: { space } }) => space[5]};
    margin-top: ${({ theme: { space } }) => space[3]};

    li {
        margin-bottom: ${({ theme: { space } }) => space[6]};
    }
`;

export const DocumentListItem = styled(Li)`
    color: black;
`;

export const ProductInformationArticle = styled(Article)`
    h1 {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }

    h3 {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }

    h5 {
        margin-bottom: ${({ theme }) => theme.space[4]};
    }
`;

export const StyledLink = styled(LinkButton)`
    text-decoration: underline;
`;
