import { DocumentNode, gql } from '@apollo/client';

export const ListFunds: DocumentNode = gql`
    query ListFunds {
        listFunds {
            cashLikeFund
            factsheetUrl
            fundCode
            fundName
            totalExpenseRate
            volatilityRating
        }
    }
`;

export const ListInvestmentPathways: DocumentNode = gql`
    query ListInvestmentPathways {
        listInvestmentPathways {
            factsheetUrl
            fundCode
            fundName
            totalExpenseRate
        }
    }
`;
