import { styled } from '@slal/ui';
import { FieldSet, Notification, relatedFieldIndent } from '~/components';

export const StyledNotification = styled(Notification)`
    margin-left: ${relatedFieldIndent};
`;

export const StyledFieldSet = styled(FieldSet)`
    margin-bottom: ${({ theme: { space } }) => space[4]};
`;
