import { IconProps } from '@slal/ui/icons';

export const LoadingSpinner = ({ title, ...otherProps }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        color="orange"
        viewBox="0 0 200 200"
        role="presentation"
        {...otherProps}
    >
        {title && <title>{title}</title>}
        <defs>
            <linearGradient id="spinner-secondHalf">
                <stop offset="0%" stopColor="currentColor" stopOpacity="0"></stop>
                <stop offset="100%" stopColor="currentColor" stopOpacity="0.5"></stop>
            </linearGradient>
            <linearGradient id="spinner-firstHalf">
                <stop offset="0%" stopColor="currentColor"></stop>
                <stop offset="100%" stopColor="currentColor" stopOpacity="0.5"></stop>
            </linearGradient>
        </defs>
        <g strokeWidth="16">
            <path stroke="url(#spinner-secondHalf)" d="M4 100a96 96 0 01192 0"></path>
            <path stroke="url(#spinner-firstHalf)" d="M196 100a96 96 0 01-192 0"></path>
            <path stroke="currentColor" strokeLinecap="round" d="M4 100a96 96 0 010-2"></path>
        </g>
        <animateTransform
            attributeName="transform"
            dur="1300ms"
            from="0 0 0"
            repeatCount="indefinite"
            to="360 0 0"
            type="rotate"
        ></animateTransform>
    </svg>
);
