import { CheckCircleIcon, ErrorIcon, InformationIcon, WarningIcon } from '@slal/ui/icons';
import { StyledNoticeCard } from './styles';
import { NoticeProps } from './types';

export const Notice = ({ children, ...otherProps }: NoticeProps) => {
    let Icon;

    const { level } = otherProps;

    switch (level) {
        case 'danger':
            Icon = ErrorIcon;
            break;
        case 'warning':
            Icon = WarningIcon;
            break;
        case 'success':
            Icon = CheckCircleIcon;
            break;
        default:
            Icon = InformationIcon;
    }

    return (
        <StyledNoticeCard role="alert" {...otherProps}>
            <Icon />
            {children}
        </StyledNoticeCard>
    );
};
