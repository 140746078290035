import { styled } from '@slal/ui';
import { Skeleton } from '@slal/ui/animations';
import { Heading, P } from '@slal/ui/typography';

export const StyledValueSummary = styled.div`
    p {
        margin-bottom: ${({ theme }) => theme.space[2]};
    }
`;

export const StyledTitle = styled(Heading)`
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
`;

export const StyledValue = styled(P)`
    font-size: ${({ theme }) => theme.fontSizes[6]};
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};
`;

export const ValueLoading = styled(Skeleton)`
    margin-bottom: ${({ theme }) => theme.space[2]};
`;
