import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { Col } from '@slal/ui/layout';
import { SmallPrint, Text } from '@slal/ui/typography';
import { Card, CardButton, FieldSet, LinkButton } from '~/components';

export const StyledPensions = styled.div`
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledPension = styled.section`
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledPensionFormFields = styled.div``;

export const StyledPensionCardValue = styled(SmallPrint)`
    color: ${({ theme }) => theme.colors.neutral__light__80};
`;

export const StyledPensionCardRightCol = styled(Col)`
    justify-content: flex-end;
    ${StyledPensionCardValue} {
        text-align: right;
    }
`;

export const StyledPensionActions = styled.div`
    color: ${({ theme }) => theme.colors.blue__secondary__80};
    margin-left: -${({ theme }) => theme.space[3]};
    margin-top: ${({ theme }) => theme.space[5]};
    display: flex;
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
`;

export const StyledLinkButton = styled(LinkButton)`
    font-size: ${({ theme }) => theme.fontSizes[3]};
`;

export const StyledDeclarationsLabel = styled(Text)`
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[4]};
`;

export const StyledDeclarationLink = styled(LinkButton)`
    text-decoration: underline;
`;

export const AddPensionCard = styled(CardButton)``;

export const PensionFieldSet = styled(FieldSet)`
    margin-bottom: 0;
`;

export const PensionCard = styled(Card)`
    min-width: 40%;

    ${breakpointUp.md`
        max-width: 70%
    `}
    ${breakpointUp.lg`
        max-width: 60%
    `}
`;
