import { styled } from '@slal/ui';
import { breakpointBetween, breakpointUp } from '@slal/ui/helpers';

export const InvestmentOptionsWrapper = styled.div`
    margin: ${({ theme: { space } }) => space[6]} 0;

    a {
        ${breakpointUp.sm`
            width: 50%;
        `}

        ${breakpointBetween('md', 'lg')`
            width: 100%;
        `}
    }

    ${breakpointUp.sm`
        display: flex;
        gap: ${({ theme: { space } }) => space[5]};
    `}

    ${breakpointBetween('md', 'lg')`
        display: block;
        width: 100%;
        `}
`;
