import { styled } from '@slal/ui';
import { Button } from '@slal/ui/buttons';
import { Anchor } from '@slal/ui/navigation';
import { Card } from '~/components';

export const EqualHeightsCard = styled(Card)`
    height: calc(100% - ${({ theme }) => theme.space[5]});
`;

export const StyledLink = styled(Anchor)`
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
        margin-left: ${({ theme }) => theme.space[2]};
        &:last-child {
            margin-top: -${({ theme }) => theme.space[2]};
        }
    }
`;

export const BookAppointmentButton = styled(Button)`
    margin-bottom: ${({ theme }) => theme.space[7]};
`;
