import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const CreateApplication: DocumentNode = gql`
    mutation CreateApplication($input: CreateApplicationInput!) {
        createApplication(input: $input) {
            estimatedTimescales
            nextSteps
        }
    }
`;
