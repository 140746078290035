import { Button } from '@slal/ui/buttons';
import { AccordionSection } from '@slal/ui/display';
import { Heading, P } from '@slal/ui/typography';
import { useCallback, useState } from 'react';
import { Article, Card, MaxWidthContent, Notification, Ul } from '~/components';
import { ListItem, StyledAccordion, StyledEstimatedTimelines } from './styles';
import { PensionConsolidationModal } from '../../pension-consolidation';

export const CREATE_APPLICATION_RESPONSE = 'CREATE_APPLICATION_RESPONSE';

const estimatedTimescalesId = 'estimated-timescales-card-title';
const nextStepsId = 'next-steps-list';

const EstimatedTimescalesCard = ({ estimatedTimescale, ...otherProps }: { estimatedTimescale: string }) => {
    return (
        <Card {...otherProps}>
            <Heading id={estimatedTimescalesId} level={4}>
                Estimated payment date
            </Heading>
            <StyledEstimatedTimelines>{estimatedTimescale}</StyledEstimatedTimelines>
        </Card>
    );
};

const NextSteps = ({ nextSteps, ...otherProps }: { nextSteps: Array<string> }) => {
    return (
        <Ul {...otherProps}>
            {nextSteps.map((step, index) => (
                <ListItem key={index}>{step}</ListItem>
            ))}
        </Ul>
    );
};

const ChangeMyMind = () => {
    return (
        <StyledAccordion>
            <AccordionSection id="change-my-mind-information" title="Can I change my mind?">
                <Article>
                    <P>You have 30 days to cancel after you receive your plan documents.</P>
                    <P>
                        After this, you'll be bound by the terms and conditions of the plan and any money received by
                        Standard Life Assurance Limited will not be refundable under the cancellation rule.
                    </P>
                    <P>
                        Before we can return any plan transferred in, you must speak with ReAssure to get their
                        agreement to accept the pension back. If you have taken tax-free cash, you must also arrange to
                        hand this back. Should this not be possible, you must arrange for another pension provider to
                        accept the transfer instead.
                    </P>
                </Article>
            </AccordionSection>
        </StyledAccordion>
    );
};

const TransferPensions = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
    const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
    return (
        <Article>
            <Heading level={4}>Want to transfer in other pensions?</Heading>
            <Button color="secondary" variant="outline" onClick={openModal}>
                Help me combine my pensions
            </Button>
            <PensionConsolidationModal isModalOpen={isModalOpen} closeModal={closeModal} />
        </Article>
    );
};

export const Confirmation = () => {
    const createApplicationResponse = JSON.parse(sessionStorage.getItem(CREATE_APPLICATION_RESPONSE)!);

    return (
        <MaxWidthContent>
            <Notification level="success" heading="Application submitted">
                <P>You'll get a confirmation email in the next 5 minutes.</P>
            </Notification>
            {createApplicationResponse?.estimatedTimescales && (
                <EstimatedTimescalesCard
                    aria-labelledBy={estimatedTimescalesId}
                    estimatedTimescale={createApplicationResponse?.estimatedTimescales}
                />
            )}
            {!!createApplicationResponse?.nextSteps?.length && (
                <Article>
                    <Heading id={nextStepsId} level={2}>
                        What happens next
                    </Heading>
                    <NextSteps aria-labelledBy={nextStepsId} nextSteps={createApplicationResponse?.nextSteps} />
                </Article>
            )}
            <TransferPensions />
            <ChangeMyMind />
        </MaxWidthContent>
    );
};
