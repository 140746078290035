import { styled } from '@slal/ui';
import { Ul } from '../Typography';

export const StyledDocuments = styled(Ul)`
    list-style-type: none;
    padding-left: ${({ theme: { space } }) => space[5]};
    margin-top: ${({ theme: { space } }) => space[3]};
`;

export const StyledDocumentListItem = styled.li`
    padding: ${({ theme }) => theme.space[4]};
    padding-bottom: ${({ theme }) => theme.space[3]};
    a,
    button {
        display: flex;
        align-items: center;
    }
`;
