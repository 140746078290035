import { styled } from '@slal/ui';
import { Article as SUIArticle } from '@slal/ui/typography';

export const Article = styled(SUIArticle)`
    padding-top: ${({ theme }) => theme.space[4]};
    h1,
    h2 {
        margin-bottom: ${({ theme }) => theme.space[6]};
    }
    p,
    h5 {
        margin-bottom: ${({ theme }) => theme.space[6]};
    }
`;
