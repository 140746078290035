import { styled } from '@slal/ui';

export const RoundelWrapper = styled.div`
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    color: white;
    font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.light};
    line-height: ${({ theme }) => theme.lineHeights.solid};
    font-size: ${({ theme }) => theme.fontSizes[11]};
    font-family: ${({ theme }) => theme.fonts.headings.family};
`;
