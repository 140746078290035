export const roundCurrency = (value: number) => Math.floor((value + Number.EPSILON) * 100) / 100;

export const formatToGBP = (value: string | number | null) => {
    if (!value) return '';

    let numericValue = value;

    if (typeof value === 'string') {
        numericValue = parseFloat(value);
        if (isNaN(numericValue)) return '';
    }

    numericValue = roundCurrency(numericValue as number);

    const formattedValue = numericValue.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formattedValue;
};
