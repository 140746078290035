import { Link } from 'react-router-dom';
import { styled } from '@slal/ui';
import { ChevronRightIcon } from '@slal/ui/icons';

export const StyledLink = styled(Link)`
    display: block;
    padding: ${({ theme: { space } }) => space[5]};
    margin-bottom: ${({ theme: { space } }) => space[5]};
    border: 1px solid ${({ theme }) => theme.colors.neutral__light__60};
    border-radius: ${({ theme: { borderRadius } }) => borderRadius.large};
    min-height: 130px;
    text-align: left;
    text-decoration: none;
    color: inherit;
    &:hover {
        border-color: ${({ theme }) => theme.colors.blue__secondary__80};
        border-style: solid;
        border-width: ${({ theme }) => theme.space[2]};
    }
`;

export const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const CardContent = styled.div`
    color: ${({ theme }) => theme.colors.neutral__light__80};
    font-size: 14px;
`;

export const NavigationIcon = styled(ChevronRightIcon)`
    color: ${({ theme }) => theme.colors.blue__secondary__80};
`;
