import { ComponentPropsWithoutRef } from 'react';
import { StyledLi, StyledLiText, StyledOl, StyledUl } from './styles';

export const Li = ({ children, ...otherProps }: ComponentPropsWithoutRef<'li'>) => (
    <StyledLi {...otherProps}>
        <StyledLiText markdown>{children}</StyledLiText>
    </StyledLi>
);

export const Ul = ({ children, ...otherProps }: ComponentPropsWithoutRef<'ul'>) => (
    <StyledUl {...otherProps}>{children}</StyledUl>
);

export const Ol = ({ children, ...otherProps }: ComponentPropsWithoutRef<'ol'>) => (
    <StyledOl {...otherProps}>{children}</StyledOl>
);
