import { ErrorWrapper, FieldErrorMessage, StyledErrorIcon } from './styles';
import { FieldErrorProps } from './types';

export const FieldError = ({ errorMessage }: FieldErrorProps) => {
    return (
        <ErrorWrapper>
            <StyledErrorIcon />
            <FieldErrorMessage>{errorMessage}</FieldErrorMessage>
        </ErrorWrapper>
    );
};
