import { styled } from '@slal/ui';

export const StyledDescriptionList = styled.dl`
    padding: 0;
    margin: 0 0 ${({ theme }) => theme.space[6]} 0;
`;

export const StyledDescriptionTerm = styled.dt`
    margin: 0 0 ${({ theme }) => theme.space[4]} 0;
`;

export const StyledDescriptionDetail = styled.dd`
    color: inherit;
    margin: 0 0 ${({ theme }) => theme.space[6]} 0;
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.bold};

    &:last-of-type {
        margin: 0;
    }
`;
