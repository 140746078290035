import {
    ApplicationPensionValues,
    ApplicationPensionValuesInput,
    LumpSumOptions,
    LumpSumOptionsFormValues,
    Pension,
} from '~/types';
import { roundCurrency } from './currency';

export const MAXIMUM_TAX_FREE_CASH_RATIO = 0.25;

export const getLumpSumValue = (
    lumpSumOptions: LumpSumOptionsFormValues,
    maximumTaxFreeCash: ApplicationPensionValues['maximumTaxFreeCash'],
    halfTaxFreeCash: ApplicationPensionValues['halfTaxFreeCash']
) => {
    const { option, specifiedAmount } = lumpSumOptions;
    return option === LumpSumOptions.MAXIMUM_TAX_FREE_CASH
        ? maximumTaxFreeCash
        : option === LumpSumOptions.HALF_TAX_FREE_CASH
          ? halfTaxFreeCash
          : specifiedAmount
            ? parseFloat(specifiedAmount?.toString())
            : 0;
};

export const getPensionValues = (application: ApplicationPensionValuesInput): ApplicationPensionValues => {
    let total: number = 0;
    let maximumRegularIncome: number = 0;

    const { lumpSumOptions, pensions, regularIncome, withdrawal } = application;

    if (!pensions)
        return {
            halfTaxFreeCash: 0,
            maximumRegularIncome,
            maximumLumpSum: 0,
            maximumTaxFreeCash: 0,
            maximumTaxFreeCashTaken: false,
            total,
        };

    for (const { value } of pensions) {
        total = total + parseFloat(value.toString());
    }

    const halfTaxFreeCash = (total / 2) * MAXIMUM_TAX_FREE_CASH_RATIO || 0;
    const maximumLumpSum = total - 1 || 0;
    const maximumTaxFreeCash = total * MAXIMUM_TAX_FREE_CASH_RATIO || 0;
    maximumRegularIncome = total / 12 || 0;

    if (
        (withdrawal?.option === 'Regular income' && !regularIncome?.amount) ||
        (withdrawal?.option !== 'Regular income' && !lumpSumOptions)
    ) {
        return {
            halfTaxFreeCash: roundCurrency(halfTaxFreeCash),
            maximumRegularIncome: roundCurrency(maximumRegularIncome),
            maximumLumpSum: roundCurrency(maximumLumpSum),
            maximumTaxFreeCash: roundCurrency(maximumTaxFreeCash),
            maximumTaxFreeCashTaken: false,
            total: roundCurrency(total) || 0,
        };
    }

    const regularIncomeWithdrawals =
        withdrawal?.option !== 'Lump sum' && regularIncome?.amount
            ? {
                  regularIncome: roundCurrency(parseFloat(regularIncome.amount.toString())),
                  regularIncomePaymentDay: regularIncome.day.toString(),
              }
            : {};

    if (withdrawal?.option === 'Regular income' && regularIncome?.amount) {
        return {
            halfTaxFreeCash: roundCurrency(halfTaxFreeCash),
            maximumRegularIncome: roundCurrency(maximumRegularIncome),
            maximumLumpSum: roundCurrency(maximumLumpSum),
            maximumTaxFreeCash: roundCurrency(maximumTaxFreeCash),
            maximumTaxFreeCashTaken: false,
            total: roundCurrency(total) || 0,
            withdrawals: {
                ...regularIncomeWithdrawals,
            },
        };
    }

    const lumpSum = getLumpSumValue(lumpSumOptions as LumpSumOptionsFormValues, maximumTaxFreeCash, halfTaxFreeCash);
    const { option } = lumpSumOptions as LumpSumOptionsFormValues;

    maximumRegularIncome = roundCurrency((total - lumpSum) / 12) || 0;

    return {
        halfTaxFreeCash: roundCurrency(halfTaxFreeCash),
        maximumRegularIncome: roundCurrency(maximumRegularIncome),
        maximumLumpSum: roundCurrency(maximumLumpSum),
        maximumTaxFreeCash: roundCurrency(maximumTaxFreeCash),
        maximumTaxFreeCashTaken: lumpSum >= maximumTaxFreeCash,
        remainderAfterLumpSum: roundCurrency(total - lumpSum),
        total: roundCurrency(total) || 0,
        withdrawals: {
            lumpSumOption: option,
            lumpSum: roundCurrency(lumpSum),
            ...regularIncomeWithdrawals,
        },
    };
};

export const formatEstimatedTimescales = (startDays: number, endDays: number): string => {
    const today = new Date();

    const startDate = new Date(today);
    startDate.setDate(today.getDate() + startDays);

    const endDate = new Date(today);
    endDate.setDate(today.getDate() + endDays);

    const options: Intl.DateTimeFormatOptions = { month: 'long' };

    const startFormatted = `${startDate.getDate()} ${startDate.toLocaleDateString(undefined, options)}`;
    const endFormatted = `${endDate.getDate()} ${endDate.toLocaleDateString(undefined, options)}`;

    return `${startFormatted} - ${endFormatted}`;
};

export const REASSURE_LIFE_LTD = 'ReAssure Life Ltd';
export const REASSURE_LTD = 'ReAssure Ltd';

export const getReAssurePensionProvider = (policyNumber: Pension['policyNumber']) => {
    if (/^[a-zA-Z]{2}[0-9]{10}$/.test(policyNumber)) return REASSURE_LIFE_LTD;
    if (/^[a-zA-Z]{2}[0-9]{9}$/.test(policyNumber)) return REASSURE_LIFE_LTD;
    if (/^[a-zA-Z]{3}[0-9]{9}$/.test(policyNumber)) return REASSURE_LIFE_LTD;
    if (/^10[1-9][0-9]{6}$/.test(policyNumber)) return REASSURE_LIFE_LTD;
    if (/^1[1-3][0-9]{7}$/.test(policyNumber)) return REASSURE_LIFE_LTD;
    return REASSURE_LTD;
};

export const getIncomeStartDate = (dayStr: string | number, ISODateFormat: boolean = false) => {
    const today = new Date();
    const currentDay = today.getDate();
    const targetDay = parseInt(dayStr.toString());
    let year = today.getFullYear();
    let month = today.getMonth() + 1;

    if (targetDay < currentDay) {
        month += 1;
    }

    if (month > 12) {
        month = 1;
        year += 1;
    }

    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = targetDay.toString().padStart(2, '0');

    return ISODateFormat ? `${year}-${formattedMonth}-${formattedDay}` : `${formattedDay}/${formattedMonth}/${year}`;
};
